var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "app-wrapper",
      class: _vm.classObj,
      style: { "--current-color": _vm.theme },
    },
    [
      _vm.device === "mobile" && _vm.sidebar.opened
        ? _c("div", {
            staticClass: "drawer-bg",
            on: { click: _vm.handleClickOutside },
          })
        : _vm._e(),
      !_vm.sidebar.hide
        ? _c("sidebar", { staticClass: "sidebar-container" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "main-container",
          class: {
            hasTagsView: _vm.needTagsView,
            sidebarHide: _vm.sidebar.hide,
          },
        },
        [
          _c(
            "div",
            { class: { "fixed-header": _vm.fixedHeader } },
            [
              _c("navbar"),
              _vm.needTagsView ? _c("tags-view") : _vm._e(),
              _c("div", { staticClass: "quziBlock" }, [
                _vm.hoverStatus
                  ? _c("img", {
                      staticClass: "title",
                      attrs: { src: require("../assets/icons/quzi-ts.png") },
                    })
                  : _vm._e(),
                _c("img", {
                  staticClass: "icon",
                  attrs: { src: require("../assets/icons/quzi.png") },
                  on: {
                    click: _vm.openQuzi,
                    mouseover: _vm.onMouseOver,
                    mouseout: _vm.onMouseOut,
                  },
                }),
              ]),
              _c(
                "el-drawer",
                {
                  attrs: {
                    visible: _vm.drawer,
                    direction: _vm.direction,
                    size: "50%",
                    "z-index": "99999",
                  },
                  on: {
                    "update:visible": function ($event) {
                      _vm.drawer = $event
                    },
                  },
                },
                [
                  _c("iframe", {
                    staticStyle: { overflow: "hidden" },
                    attrs: {
                      src: "https://chatbot.weixin.qq.com/webapp/fMP4Axp4LsyCsWXv6yQJif7zLOffh8?robotName=%E5%B0%8F%E5%9F%9F%E6%9C%8D%E5%8A%A1%E5%95%86%E5%8A%A9%E6%89%8B",
                      frameborder: "no",
                      border: "0",
                      marginwidth: "0",
                      marginheight: "0",
                      width: "100%",
                      height: "100%",
                    },
                  }),
                ]
              ),
            ],
            1
          ),
          _c("app-main"),
          _c("right-panel", [_c("settings")], 1),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }