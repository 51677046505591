var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "scrollDiv", staticClass: "container" },
    [
      _vm.isRecShow ? _c("div", { staticClass: "mask" }) : _vm._e(),
      _vm.total > _vm.pageSize
        ? _c("div", { staticClass: "more", on: { click: _vm.scrollToUpper } }, [
            _vm._v("点击加载更多"),
          ])
        : _vm._e(),
      _vm._l(_vm.chatList, function (item, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "chat-box",
            class: {
              endT: index + 1 == _vm.chatList.length && _vm.isRec,
              endR: (index + 1 == _vm.chatList.length) & !_vm.isRec,
            },
          },
          [
            _c("div", { staticClass: "c-time" }, [
              _vm._v(_vm._s(item.callTime ? item.callTime : "")),
            ]),
            _c(
              "div",
              {
                staticClass: "c-user",
                class: item.launchRole == 1 ? "r-user" : "",
              },
              [
                item.launchRole == 1
                  ? _c("img", {
                      staticClass: "c-img",
                      attrs: { src: require("@/assets/images/base.png") },
                    })
                  : _c("div", [
                      item.userImg
                        ? _c("img", {
                            staticClass: "c-img",
                            attrs: { src: item.userImg },
                          })
                        : _c("img", {
                            staticClass: "c-img",
                            attrs: { src: require("@/assets/images/base.png") },
                          }),
                    ]),
                item.launchRole != 1
                  ? [
                      item.recordType == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "c-content",
                              on: {
                                click: function ($event) {
                                  return _vm.player(item, index)
                                },
                              },
                            },
                            [
                              !item.isPlay
                                ? _c("img", {
                                    staticClass: "c-icon",
                                    attrs: {
                                      src: require("@/assets/images/l-play.png"),
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "c-icon",
                                    attrs: {
                                      src: require("@/assets/images/play-l.gif"),
                                    },
                                  }),
                              _c("div", [
                                _vm._v(
                                  "00:" +
                                    _vm._s(
                                      item.duration
                                        ? item.duration - 0 < 10
                                          ? "0" + item.duration
                                          : item.duration
                                        : "00"
                                    )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      item.recordType == 2
                        ? _c("div", { staticClass: "content-txt-stu" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.launchText ? item.launchText : "") +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      item.recordType == 3
                        ? _c("img", {
                            staticStyle: { height: "300px", width: "300px" },
                            attrs: { src: item.emojiCode },
                          })
                        : _vm._e(),
                    ]
                  : [
                      item.recordType == 1
                        ? _c(
                            "div",
                            {
                              staticClass: "c-content r-c",
                              on: {
                                click: function ($event) {
                                  return _vm.player(item, index)
                                },
                              },
                            },
                            [
                              !item.isPlay
                                ? _c("img", {
                                    staticClass: "c-icon c-icon-j",
                                    attrs: {
                                      src: require("@/assets/images/play-l.png"),
                                    },
                                  })
                                : _c("img", {
                                    staticClass: "c-icon c-icon-j",
                                    attrs: {
                                      src: require("@/assets/images/zt-n.gif"),
                                    },
                                  }),
                              _c("div", [
                                _vm._v(
                                  "00:" +
                                    _vm._s(
                                      item.duration
                                        ? item.duration - 0 < 10
                                          ? "0" + item.duration
                                          : item.duration
                                        : "00"
                                    )
                                ),
                              ]),
                            ]
                          )
                        : _vm._e(),
                      item.recordType == 2
                        ? _c("div", { staticClass: "content-txt" }, [
                            _vm._v(
                              " " +
                                _vm._s(item.launchText ? item.launchText : "") +
                                " "
                            ),
                          ])
                        : _vm._e(),
                      item.recordType == 3
                        ? _c("img", {
                            staticStyle: { height: "300px", width: "300px" },
                            attrs: { src: item.emojiCode },
                          })
                        : _vm._e(),
                    ],
              ],
              2
            ),
            item.launchRole != 1
              ? _c(
                  "div",
                  {
                    staticClass: "c-info",
                    class: item.launchRole == 1 ? "r-info" : "",
                  },
                  [
                    _c("div", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.title)),
                    ]),
                    _c("div", [
                      _vm._v(
                        "此条信息来自于" +
                          _vm._s(
                            item.equipmentType == 1
                              ? "【话机】"
                              : item.equipmentType == 2
                              ? "【班牌】"
                              : ""
                          )
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        )
      }),
      _c(
        "div",
        { staticClass: "bottom-wrap", class: _vm.isRec ? "" : "bottom-txt" },
        [
          _vm.isRec
            ? _c("div", { staticClass: "b-rec" }, [
                _c(
                  "div",
                  { staticClass: "click-rec", on: { click: _vm.bindRec } },
                  [_vm._v("点击录音 ")]
                ),
                _c(
                  "div",
                  { staticClass: "txt-btn", on: { click: _vm.clickPhiz } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/phiz.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
                _c(
                  "div",
                  { staticClass: "txt-btn", on: { click: _vm.switchType } },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/chat-y.png"),
                        alt: "",
                      },
                    }),
                  ]
                ),
              ])
            : _c(
                "div",
                { staticClass: "b-txt" },
                [
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.switchType } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/chat-t.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "btn", on: { click: _vm.clickPhiz } },
                    [
                      _c("img", {
                        attrs: {
                          src: require("@/assets/images/phiz.png"),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _c("el-input", {
                    ref: "input",
                    staticStyle: { "font-size": "50px" },
                    attrs: {
                      type: "textarea",
                      autosize: "{ minRows: 1, maxRows: 2 }",
                    },
                    model: {
                      value: _vm.areaTxt,
                      callback: function ($$v) {
                        _vm.areaTxt = $$v
                      },
                      expression: "areaTxt",
                    },
                  }),
                  _c(
                    "el-button",
                    {
                      staticStyle: {
                        margin: "0 20px",
                        width: "170px",
                        height: "80px",
                        "font-size": "32px",
                      },
                      attrs: { size: "medium", type: "primary" },
                      on: { click: _vm.send },
                    },
                    [_vm._v("发送")]
                  ),
                ],
                1
              ),
          _vm.isPhiz
            ? _c(
                "div",
                {
                  staticStyle: {
                    height: "600px",
                    width: "100%",
                    "overflow-y": "auto",
                    display: "flex",
                    "flex-wrap": "wrap",
                    "margin-top": "20px",
                    padding: "20px",
                  },
                },
                _vm._l(_vm.phizList, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticStyle: {
                        height: "auto",
                        width: "20%",
                        "margin-top": "55px",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.pushPhiz(item)
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "100%", height: "100%" },
                        attrs: { src: item },
                      }),
                    ]
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
      _c("transition", { attrs: { name: "bounce" } }, [
        false
          ? _c("div", { staticClass: "b-top" }, [
              _vm.leave
                ? _c("img", {
                    attrs: {
                      src: require("@/assets/images/luyin-n.png"),
                      alt: "",
                    },
                  })
                : _c("img", {
                    attrs: {
                      src: require("@/assets/images/luyin.gif"),
                      alt: "",
                    },
                  }),
              _c("div", { staticStyle: { "margin-bottom": "10px" } }, [
                _vm._v("正在录音 (" + _vm._s(_vm.time) + "s)"),
              ]),
              _vm.leave
                ? _c("div", {}, [_vm._v("松开手指，取消发送")])
                : _vm._e(),
            ])
          : _vm._e(),
      ]),
      _c("transition", { attrs: { name: "bounce" } }, [
        false
          ? _c("div", { staticClass: "b-bottom" }, [
              _c("div", { staticStyle: { "margin-bottom": "40px" } }, [
                _vm._v("松开发送，上滑取消"),
              ]),
              _c("img", {
                attrs: { src: require("@/assets/images/chating.png"), alt: "" },
              }),
            ])
          : _vm._e(),
      ]),
      _vm.isRecShow
        ? _c("div", { staticClass: "record-box" }, [
            _c("div", { staticClass: "top" }, [
              _vm._m(0),
              _c("div", { staticClass: "top-txt" }, [
                _vm._v("正在录音（" + _vm._s(_vm.time) + "s）"),
              ]),
            ]),
            _c("div", { staticClass: "bottom" }, [
              _c("div", { staticClass: "btn", on: { click: _vm.cancel } }, [
                _vm._v("取消"),
              ]),
              _c(
                "div",
                { staticClass: "btn send", on: { click: _vm.stopRecord } },
                [_vm._v("发送")]
              ),
            ]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "top-r" }, [
      _c("div", { staticClass: "wrap" }, [
        _c("div", { staticClass: "music" }, [
          _c("div", { staticClass: "li m1" }),
          _c("div", { staticClass: "li m2" }),
          _c("div", { staticClass: "li m3" }),
          _c("div", { staticClass: "li m1" }),
          _c("div", { staticClass: "li m2" }),
          _c("div", { staticClass: "li m3" }),
          _c("div", { staticClass: "li m1" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }