<template>
    <div class="container" ref="scrollDiv">
        <!-- <button @click="clickHandke"> -->
        <!-- <a href="http://test.mpay8.cn/pages/20230524/Dmq49cjnkRQBood.html">拉起微信</a> -->
        <!-- </button> -->
        <div class="mask" v-if="isRecShow"></div>
        <div class="more" v-if="total > pageSize" @click="scrollToUpper">点击加载更多</div>
        <!-- <audio src="https://xyw-bucket-dev.oss-cn-hangzhou.aliyuncs.com/ddvoice2023/03/30/new_20230330175243A002.mp3" controls>
      </audio> -->
        <!-- <button @click="bf">播放</button> -->
        <div class="chat-box"
            :class="{ endT: (index + 1 == chatList.length && isRec), endR: (index + 1 == chatList.length & !isRec) }"
            v-for="(item, index) in chatList" :key="index">
            <div class="c-time">{{ item.callTime ? item.callTime : '' }}</div>
            <div class="c-user" :class="item.launchRole == 1 ? 'r-user' : ''">
                <img v-if="item.launchRole == 1" class="c-img" src="@/assets/images/base.png">
                <div v-else>
                    <img v-if="item.userImg" class="c-img" :src="item.userImg">
                    <img v-else class="c-img" src="@/assets/images/base.png">
                </div>
              <!-- 学生录音 对话框 -->
               <template v-if="item.launchRole != 1">
                 <div class="c-content" v-if="item.recordType == 1" @click="player(item, index)">
                   <img v-if="!item.isPlay" class="c-icon" src="@/assets/images/l-play.png">
                   <img v-else class="c-icon" src="@/assets/images/play-l.gif">
                   <!-- <div class="bar"></div> -->
                   <div>00:{{ item.duration ? ((item.duration - 0) < 10 ? ('0' + item.duration) : item.duration) : '00'
                     }}</div>
                 </div>
                 <!-- 文字框 -->
                 <div class="content-txt-stu" v-if="item.recordType == 2">
                   {{ item.launchText ? item.launchText : '' }}
                 </div>
                 <img style="height: 300px;width: 300px" :src="item.emojiCode" v-if="item.recordType == 3"></img>
               </template>

                <!-- 家长 对话框 -->
                <template v-else>
                    <!-- 录音框 -->
                    <div v-if="item.recordType == 1" class="c-content r-c" @click="player(item, index)">
                        <img v-if="!item.isPlay" class="c-icon c-icon-j" src="@/assets/images/play-l.png">
                        <img v-else class="c-icon c-icon-j" src="@/assets/images/zt-n.gif">
                        <!-- <div class="bar"></div> -->
                        <div>00:{{ item.duration ? ((item.duration - 0) < 10 ? ('0' + item.duration) : item.duration)
                            : '00' }}</div>
                    </div>
                    <!-- 文字框 -->
                    <div class="content-txt" v-if="item.recordType == 2">
                        {{ item.launchText ? item.launchText : '' }}
                    </div>
                    <img style="height: 300px;width: 300px" :src="item.emojiCode" v-if="item.recordType == 3"></img>
                </template>
                </div>
                <div class="c-info" :class="item.launchRole == 1 ? 'r-info' : ''" v-if="item.launchRole != 1">
                    <div class="name">{{ title }}</div>
                    <div>此条信息来自于{{ item.equipmentType == 1 ? '【话机】' : item.equipmentType == 2 ? '【班牌】' : '' }}</div>
                    <!-- <div>此条信息来自于【话机】</div> -->
                </div>
            </div>
            <!-- <div @touchstart="touchstart" @touchend="touchend" @touchmove="touchmove" class=""
                style="position: absolute;bottom: 150px;left:150px;width: 100px;height: 50px;background-color: red;">长按滑动
            </div> -->

            <!-- 底部 -->
            <div class="bottom-wrap" :class="isRec ? '' : 'bottom-txt'">
                <div class="b-rec" v-if="isRec">
                    <!--    @click="bindRec"  @touchstart="touchstart" @touchend="touchend" @touchmove="touchmove"-->
                    <div class="click-rec" @click="bindRec">点击录音
                    </div>
                    <div class="txt-btn" @click="clickPhiz">
                      <img src="@/assets/images/phiz.png" alt="">
                    </div>
                    <div class="txt-btn" @click="switchType">
                        <img src="@/assets/images/chat-y.png" alt="">
                    </div>

                    <!-- <div class="txt-btn" @click="switchType">切换文字</div> -->
                </div>
                <div class="b-txt" v-else>
                    <div class="btn" @click="switchType">
                        <img src="@/assets/images/chat-t.png" alt="">
                    </div>
                    <div class="btn" @click="clickPhiz">
                      <img src="@/assets/images/phiz.png" alt="">
                    </div>
                    <!--  @keydown.enter.native="handleTextareaKeydown"   @input="areaIpt" -->
                    <el-input  type="textarea" ref="input" autosize = "{ minRows: 1, maxRows: 2 }" style="font-size: 50px"  v-model="areaTxt" >
                    </el-input>
                    <el-button style="margin: 0 20px;width: 170px;height: 80px;font-size: 32px"   size="medium" type="primary" @click="send">发送</el-button>
                </div>
                <div v-if="isPhiz" style="height: 600px;width: 100%;overflow-y: auto;display: flex;flex-wrap: wrap;margin-top: 20px; padding: 20px; ">
                  <div @click="pushPhiz(item)" v-for="(item,index) in phizList" :key="index"  style="height: auto;width: 20%; margin-top:55px">
                    <img :src="item" style="width: 100%;height: 100%">
                  </div>
                </div>
            </div>
            <!-- 按住的录音弹窗 -->
            <transition name="bounce">
                <div class="b-top" v-if="false">
                    <img v-if="leave" src="@/assets/images/luyin-n.png" alt="">
                    <img v-else src="@/assets/images/luyin.gif" alt="">
                    <div class="" style="margin-bottom: 10px;">正在录音 ({{ time }}s)</div>
                    <div v-if="leave" class="">松开手指，取消发送</div>
                </div>
            </transition>
            <transition name="bounce">
                <div class="b-bottom" v-if="false">
                    <div class="" style="margin-bottom: 40px;">松开发送，上滑取消</div>
                    <img src="@/assets/images/chating.png" alt="">
                </div>

            </transition>
            <!-- 录音弹窗 isRecShow-->
            <div class="record-box" v-if="isRecShow">
                <div class="top">
                    <div class="top-r">
                        <div class="wrap">
                            <div class="music">
                                <div class="li m1"></div>
                                <div class="li m2"></div>
                                <div class="li m3"></div>
                                <div class="li m1"></div>
                                <div class="li m2"></div>
                                <div class="li m3"></div>
                                <div class="li m1"></div>
                            </div>
                        </div>
                    </div>
                    <div class="top-txt">正在录音（{{ time }}s）</div>
                </div>
                <div class="bottom">
                    <div class="btn" @click="cancel">取消</div>
                    <div class="btn send" @click="stopRecord">发送</div>
                </div>
            </div>
        </div>
</template>
<script src="">
if (navigator.userAgent.indexOf('AliApp') > -1) {
    document.writeln('<script src="https://appx/web-view.min.js"' + '>' + '<' + '/' + 'script>');
}
</script>
<script>
import Recorder from "js-audio-recorder";
import {
    getChat,
    getChatDD,
    saveVoice,
    uploadVideo
} from '@/api/chat'
// import { loadJS } from "@/utils/wmtools"
// loadJS("https://appx/web-view.min.js", function () {
//     console.log("加载成功")
// });
// import wav from "@/assets/audio/file_20230329144508A013.WAV";
// import wav1 from "@/assets/audio/111_20230329152416A014.WAV";
export default {
    name: "index",
    // beforeRouteLeave(to, form, next) {
    //    console.log(78910);
    //     next()
    // },
    data() {
        return {
            recorder: new Recorder({
                sampleBits: 16, // 采样位数，支持 8 或 16，默认是16
                sampleRate: 16000, // 采样率，支持 11025、16000、22050、24000、44100、48000，根据浏览器默认值，我的chrome是48000
                numChannels: 1, // 声道，支持 1 或 2， 默认是1
                // compiling: false,(0.x版本中生效,1.x增加中)  // 是否边录边转换，默认是false
            }),
            // 录音列表
            chatList: [],
            isPlay: false,
            isRec: 1, // 底部录音、文字切换
            isPhiz:false,
            areaTxt: '',
            isRecShow: false, // 录音弹窗
            leave: false,
            time: 60,
            timer: null,
            name: '',
            id: '',
            pId: '',
            schoolId: '',
            isDD: '',
            studentName: '',
            pageSize: 10,
            total: 0, // 总条数
            au: null, // 音频播放对象
            isInput: false, // 文本还是语音
            isTop: false,
            audioUrl: 'https://xyw-bucket-dev.oss-cn-hangzhou.aliyuncs.com/2023/03/29/file_20230329152416A014.WAV',
            testTimer: null,
            startPoint: '', // 记录触摸点的坐标信息
            phizList:["https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/OK.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E4%BD%A0%E5%8F%AF%E4%BB%A5%E7%9A%84.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%86%99%E4%BD%9C%E4%B8%9A.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%8A%A0%E6%B2%B9%E9%B8%AD.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%93%88%E5%93%88%E5%93%88.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%93%8E.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%97%AF%E5%97%AF.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%A4%A7%E5%93%AD.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%A7%94%E5%B1%88.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E5%AE%B3%E6%80%95.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E6%87%B5.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E6%88%91%E7%88%B1%E5%AD%A6%E4%B9%A0.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E6%8A%B1%E6%8A%B1.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E6%8B%9C%E6%8B%9C.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E6%B1%97.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E7%82%B9%E8%B5%9E.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E7%88%B1%E4%BD%A0.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E8%8A%B1%E8%8A%B1.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E8%B0%A2%E8%B0%A2.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E8%B7%BA%E8%84%9A.gif",
              "https://xyw-bucket-prod.oss-cn-hangzhou.aliyuncs.com/emoji/%E8%A1%A8%E6%83%85%E5%8C%85gif/%E9%97%AE%E5%8F%B7.gif"]
        };
    },
    mounted() {
        console.log('mounted');
        document.title = '家校留言'
        this.scrollBottom()
        // 接收来自小程序的消息。
        my.onMessage = e => { // 这里
            console.log(6996);
            this.id = e.studentId
            this.pId = e.parentId
            this.isDD = e.enablePlatform == 2 ? true : false
            this.schoolId = e.schoolId
            this.studentName = e.studentName
            // this.id = 80266
            // this.pId = 359733
            // this.isDD = 2
            // this.studentName = '腕豪'
            // this.schoolId = 170
            this.getList()
        };
    },
    created() {
        // navigator.mediaDevices.getUserMedia({ audio: true }).then(function (stream){})
        // navigator.mediaDevices.getUserMedia({ audio: true }).then(res => {
        //     console.log(res);
        // })
        // test在这测试查列表
        // this.getList()
        // 网页向小程序 postMessage 消息
        my.postMessage({ // 这里
            name: "测试web-view"
        });
        // let url = 'https://xyw-bucket-dev.oss-cn-hangzhou.aliyuncs.com/2023/03/29/file_20230329152416A014.WAV'
        // console.log(this.downloadMP3ToBase64(url));
        // Recorder.getPermission().then(() => {
        //     console.log('给权限了');
        // }, (error) => {
        //     console.log(`录音失败`);
        //     alert('录音权限获取失败')
        // });
    },
    beforeDestroy() {
        // alert('销毁s')
        this.cancel()
    },
    methods: {
        // 按住
        touchstart(e) {
            console.log(e.touches[0], 'e.touches[0]');
            this.testTimer = setTimeout(() => {
                this.bindRec()
                this.startPoint = e.touches[0] //记录触摸点的坐标信息
            }, 500)
            console.log(this.isRecShow, ' this.isRecShow');
        },
        // 滑动
        touchmove: function (e) {
            // 计算距离，当滑动的垂直距离大于200时，则取消发送语音
            if (Math.abs(e.touches[e.touches.length - 1].clientY - this.startPoint.clientY) > 200) {
                console.log('取消');
                this.leave = true
                // this.setData({
                //     //   is_clock: false//设置为不发送语音
                //     isConfirm: false
                // })
            } else {
                console.log('展示');
                this.isRecShow = true
                this.leave = false
                // this.setData({
                //     //   is_clock: false//设置为不发送语音
                //     isConfirm: true
                // })
            }
        },
        // 结束
        touchend() {
            if (this.leave) {
                clearTimeout(this.testTimer); // 清除计时器
                this.leave = false
                this.cancel()
                console.log(this.time, 'this.time');
                return
            }
            if (this.testTimer) {
                clearTimeout(this.testTimer); // 清除计时器
                this.leave = false
                this.stopRecord()
            }
        },

        clickHandke() {

        },
        bf() {
            let audioTest = new Audio
            // audioTest.src = require('@/static/111_20230329152416A014.wav')
            // audioTest.src = wav
            audioTest.play()
        },
        // 查数据
        async getList() {
            my.showLoading() //这里
            let data = {
                parentId: this.pId,
                studentId: this.id,
                schoolId: this.schoolId,
                pageNum: 1,
                pageSize: this.pageSize
            }


            let res = {}
            if (this.isDD) {
                res = await getChatDD(data)
            } else {
                res = await getChat(data)
            }
            // const res = this.isDD ?  : await getChatDD(data)
            // alert(JSON.stringify(res))
            if (res.code == 200) {
                console.log(res.data.rows, 'res')
                my.hideLoading()   // 这里
                res.data.rows.forEach(v => {
                    v.isPlay = false
                });
                this.chatList = res.data.rows
                this.title = res.data.rows.length == 0 ? this.studentName : res.data.rows[0].studentName
                this.total = res.data.total
                console.log(this.chatList);
                this.scrollBottom()
            }

        },
        //  滚到底部
        scrollBottom() {
            this.$nextTick(() => {
                let scrollElem = this.$refs.scrollDiv;
                scrollElem.scrollTop = this.isTop ? 0 : scrollElem.scrollHeight;
            });
        },
        // 切换类型
        switchType() {
            this.scrollBottom()
            this.isRec = !this.isRec
            if (!this.isRec){
              this.isPhiz = false
            }
            this.isInput = !this.isInput
        },
        // 切换表情
        clickPhiz() {
          this.scrollBottom()
          if (!this.isRec){
            this.isRec = !this.isRec
            this.isInput = !this.isInput
          }
          this.isPhiz = !this.isPhiz
        },
        // 录音弹窗
        bindRec() {

            // 开始录音
            // Recorder.getPermission().then(

            // () => {
            my.showLoading() // 这里
            console.log("开始录音");
            // this.recorder.start(); // 开始录音
            this.recorder.start().then(() => {
                // 开始录音
                this.isRecShow = true
                this.countDown()
                my.hideLoading()   //这里
            }, (error) => {
                my.hideLoading()    //这里
                // 出错了
                console.log(`${error.name} : ${error.message}`);
                this.cancel()
            });


            // },
            // (error) => {
            // my.hideLoading()
            // // ${error.name} : ${error.message}
            // // alert(`录音失败`)
            // alert(`${error.name} : ${error.message}`);
            // this.cancel()
            // }
            // );
        },
        cancel() {
            this.recorder.stop();
            this.isRecShow = false
            if (!this.isRecShow) {
                clearInterval(this.timer)
                clearTimeout(this.timerR)
                this.time = 60
                this.timer = null
            }
        },
        // 播放/暂停
        player(item, index) {

            console.log(item.voicePath);
            this.chatList.forEach((v, i) => {
                if (i == index) {
                    v.isPlay = !v.isPlay
                    if (v.isPlay) {
                        // 如果有先 清空 避免声音重叠
                        if (this.au != null) {
                            this.au.pause();
                            this.au = null;
                        }
                        this.au = new Audio()
                        this.au.src = item.voicePath

                        // this.au.src = 'https://xyw-bucket-dev.oss-cn-hangzhou.aliyuncs.com/2023/03/09/record1678354940028_20230309174221A118.ogg'
                        this.au.play()
                        // 监听audio 播放完毕
                        this.au.addEventListener('ended', function () {
                            v.isPlay = false
                        }, false);
                    } else {
                        this.au.pause()
                    }
                } else {
                    v.isPlay = false
                }
            })
        },
        // 发送
        send() {
            this.isTop = false
            this.scrollBottom()
            this.save()
        },
        // 发送表情包
        pushPhiz(e) {
          this.isTop = false
          this.scrollBottom()
          this.savePhiz(e)
        },
        // 存
        async save(url, time) {
            console.log(789);
            let voiceTime = 60 - time
            if (this.isInput && this.areaTxt.trim() == '') {
                my.alert({  //这里
                    content: '内容不能为空',
                });
                // alert('内容不能为空');
                my.hideLoading() //这里
                return
            }
            my.showLoading() // 这里
            let data = {
                parentId: this.pId,
                studentId: this.id,
                voiceTime: this.isInput ? '' : voiceTime,
                recordType: this.isInput ? 2 : 1,
                fileUrl: this.isInput ? '' : url,
                launchText: this.isInput ? this.areaTxt : '',
            }
            console.log(data, 'datadatadatadata999');
            const res = await saveVoice(data)
            if (res.code == 200) {
                this.chatList = []
                this.areaTxt = ''
                this.pageNum = 1
                this.getList()
            }
        },
        //发送表情包
        async savePhiz(e){
          if (!e) {
            my.alert({  //这里
              content: '请选择表情包发送',
            });
            // alert('内容不能为空');
            my.hideLoading() //这里
            return
          }
          my.showLoading() // 这里
          let data = {
            parentId: this.pId,
            studentId: this.id,
            voiceTime:'',
            recordType:3,
            fileUrl:'',
            launchText:'',
            emojiCode:e
          }
          const res = await saveVoice(data)
          if (res.code == 200) {
            this.chatList = []
            this.areaTxt = ''
            this.pageNum = 1
            this.getList()
          }
          this.isPhiz=false
        },
        // 更多
        scrollToUpper() {
            this.isTop = true
            if (this.pageSize >= this.total) {
                my.showToast({ //这里
                    content: '暂无更多'
                })
                // alert('暂无更多')
                return
            }
            this.pageSize = this.pageSize + 10
            this.$nextTick(() => {
                let scrollElem = this.$refs.scrollDiv;
                scrollElem.scrollTop = 0;
            });
            this.getList()
        },
        // 倒计时
        countDown() {
            let time = this.time
            this.timer = setInterval(() => {
                time--;
                this.time = time
                if (time <= 1) {
                    this.stopRecord()
                    clearInterval(this.timer)
                    this.time = 60
                    this.timer = null
                    this.isRecShow = false
                }
            }, 1000)
        },
        stopRecord() {
            this.recorder.stop();
            let voiceTime = this.time - 0
            if (voiceTime == 60) {
                this.isRecShow = false
                clearInterval(this.timer)
                clearTimeout(this.timerR)
                this.time = 60
                this.timer = null
                alert('说话时间太短')
                return
            }
            console.log(voiceTime, 'voiceTime');
            this.isRecShow = false
            if (!this.isRecShow) {
                clearInterval(this.timer)
                clearTimeout(this.timerR)
                this.time = 60
                this.timer = null
            }

            let wavBlob = this.recorder.getWAVBlob();
            // 创建一个formData对象
            let formData = new FormData()
            // 此处获取到blob对象后需要设置fileName满足当前项目上传需求，其它项目可直接传把blob作为file塞入formData
            const newbolb = new Blob([wavBlob], {
                type: 'audio/wav'
            })
            //获取当时时间戳作为文件名
            const fileOfBlob = new File([newbolb], new Date().getTime() + '.wav')
            formData.append('file', fileOfBlob)
            formData.append('busiType', 'video')
            uploadVideo(formData).then((response) => {
                this.save(response.data.url, voiceTime)
            });
        },
        downloadMP3ToBase64(url) {
            return new Promise((resolve, reject) => {
                fetch(url).then(res => res.blob()).then(blob => {
                    blobToBase64(blob).then(res => {
                        let base64 = 'data:audio/wav;base64,' + res.split(',')[1];
                        resolve(base64);
                    })
                })
            })
        }
    },
    watch: {},

};
</script>

<style scoped lang="scss">
* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.container {
    height: 100vh;
    background-color: #F1F1F1;
    overflow: auto;

    .mask {
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 10;
        background-color: rgba(0, 0, 0, 0.7);
    }

    .more {
        text-align: center;
        color: #333;
        margin-top: 30px;
    }

    .chat-box {
        overflow: hidden;
        margin-left: 32px;

        .c-time {
            text-align: center;
            font-size: 22px;
            color: #B6B8C1;
            margin: 20px 0;
        }

        .c-user {
            display: flex;
            align-items: center;

            .c-img {
                width: 80px;
                height: 80px;
                margin-right: 20px;
                border-radius: 50%;
            }

            .c-content {
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                // width: 220px;
                height: 80px;
                background: #FFFFFF;
                border-radius: 8px;
                font-size: 28px;
                color: #333;
                padding: 0 10px;
                box-sizing: border-box;

                .c-icon {
                    width: 40px;
                    height: 40px;
                    margin-right: 10px;
                }

                .c-icon-j {
                    margin-right: 0;
                    margin-left: 10px;
                }

                .bar {
                    width: 322px;
                    height: 5px;
                    margin-right: 12px;
                    background: #F4F5F7;
                    border-radius: 0px 24px 24px 0px;
                }
            }

            .r-c {
                flex-direction: row-reverse;
                background-color: #0267FE;
                color: #fff;
            }

            .content-txt {
                line-height: 50px;
                max-width: 486px;
                background: #0267FE;
                border-radius: 12px;
                color: #fff;
                font-size: 28px;
                padding: 20px;
                box-sizing: border-box;
                word-break: break-all;
            }

            .content-txt-stu {
              line-height: 50px;
              max-width: 486px;
              background: #fff;
              border-radius: 12px;
              color: black;
              font-size: 28px;
              padding: 20px;
              box-sizing: border-box;
              word-break: break-all;
            }
        }

        .r-user {
            flex-direction: row-reverse;
            align-items: unset;

            .c-img {
                margin-right: 32px;
                margin-left: 20px;
            }
        }

        .c-info {
            display: flex;
            color: #B6B8C1;
            font-size: 20px;
            margin-top: 8px;

            .name {
                width: 80px;
                white-space: nowrap;
                text-align: center;
                font-size: 20px;
                color: #666;
                margin-right: 27px;
            }
        }

        .r-info {
            flex-direction: row-reverse;
        }
    }

    .endT {
        margin-bottom: 200px;
    }

    .endR {
        margin-bottom: 260px;
    }



    .bounce-enter-active {
        transition: all .5s;
    }

    .bounce-leave-active {
        transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .bounce-enter,
    .bounce-leave-to {
        transform: translateX(10px);
        opacity: 0;
    }

    .b-top {
        position: absolute;
        top: 400px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 11;
        text-align: center;
        font-size: 28px;
        transition: all .3s ease 0s;
        color: #fff;

        img {
            width: 180px;
            height: 180px;
            margin-bottom: 32px;
        }
    }

    .b-bottom {
        position: absolute;
        bottom: -8px;
        left: 0;
        right: 0;
        z-index: 11;
        text-align: center;
        color: #fff;
        font-size: 28px;
        transition: all .3s ease 0s;

        img {
            width: 100%;
            height: 267rpx;
        }
    }

    .bottom-wrap {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 142px;
        background: #F4F5F7;
        box-shadow: 0px -3px 20px 0px rgba(241, 241, 241, 0.5038);

        .b-rec {
            display: flex;
            flex-direction: row-reverse;
            align-items: center;
            justify-content: space-evenly;
            margin-top: 30px;

            .click-rec {
                width: 530px;
                height: 72px;
                line-height: 72px;
                text-align: center;
                background: #FFFFFF;
                border-radius: 10px;
                font-size: 32px;
                font-weight: 700;
                color: #333;

                &:active {
                    opacity: .5;
                }
            }

            .txt-btn {
                width: 56px;
                height: 56px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .b-txt {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            font-size: 26px;
            margin-top: 30px;

            .area-txt {
                width: 596px;
                min-height: 180px;
                background: #EFF0F3;
                border-radius: 20px;
                border: 0;
                outline: none;
                font-size: 26px;
                padding: 18px;
                box-sizing: border-box;
            }

            .btn {
                flex-shrink: 0;
                width: 56px;
                height: 56px;
                margin: 0 20px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .btn-send {
                border: 1px solid #0267FE;
                color: #0267FE;
                background-color: #E5EFFE;
            }
        }
    }

    .bottom-txt {
        // height: 237px;
    }

    // 录音弹窗
    .record-box {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        background: #fff;
        z-index: 101;

        .top {
            width: 100%;
            height: 278px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .top-r {
                width: 131px;
                height: 131px;
                background-color: #E5EFFE;
                border-radius: 40px;
            }

            .top-txt {
                font-size: 28px;
                color: #0267FE;
                margin-top: 20px;
            }
        }

        .bottom {
            width: 100%;
            height: 182px;
            padding: 0 32px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            font-size: 26px;

            .btn {
                width: 128px;
                height: 56px;
                border-radius: 32px;
                border: 1px solid #333333;
                color: #333;
                text-align: center;
                line-height: 56px;
                margin-top: 38px;
            }

            .send {
                background-color: #E5EFFE;
                border: 1px solid #0267FE;
                color: #0267FE;
            }
        }
    }




    .wrap {
        width: 100%;
        height: 100px;
        position: relative;
    }

    .wrap .music {
        position: absolute;
        bottom: 30px;
        margin-left: 10px;
        display: inline-block;
        vertical-align: baseline;
        font-size: 0;
    }

    .wrap .music .li {
        background-color: #0267FE;
        margin-left: 5px;
        display: inline-block;
        width: 10px;
        height: 50px;
        border-radius: 40px;
    }

    .m1 {
        -webkit-animation: .8s .1s living linear infinite backwards normal;
        animation: .8s .1s living linear infinite backwards normal;
        -webkit-animation-delay: -1.1s;
    }

    .m2 {
        -webkit-animation: .8s .3s living linear infinite backwards normal;
        animation: .8s .3s living linear infinite backwards normal;
        -webkit-animation-delay: -1.3s;
    }

    .m3 {
        -webkit-animation: .8s .6s living linear infinite backwards normal;
        animation: .8s .6s living linear infinite backwards normal;
        -webkit-animation-delay: -1.6s;
    }

    @-webkit-keyframes living {
        0% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 0 50px;
            transform-origin: 0 50px
        }

        50% {
            -webkit-transform: scaleY(.3);
            transform: scaleY(.3);
            -webkit-transform-origin: 0 50px;
            transform-origin: 0 50px
        }

        100% {
            -webkit-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 0 50px;
            transform-origin: 0 50px
        }
    }

    @keyframes living {
        0% {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 0 50px;
            -ms-transform-origin: 0 50px;
            transform-origin: 0 50px
        }

        50% {
            -webkit-transform: scaleY(.3);
            -ms-transform: scaleY(.3);
            transform: scaleY(.3);
            -webkit-transform-origin: 0 50px;
            -ms-transform-origin: 0 50px;
            transform-origin: 0 50px
        }

        100% {
            -webkit-transform: scaleY(1);
            -ms-transform: scaleY(1);
            transform: scaleY(1);
            -webkit-transform-origin: 0 50px;
            -ms-transform-origin: 0 50px;
            transform-origin: 0 50px
        }
    }
}

::v-deep {
    .el-textarea {
        // width: 598px !important;
        min-height: 72px !important;
    }
}

::v-deep {
    .el-textarea__inner {
        // width: 598px !important;
        max-height: 72px !important;
        overflow-y: auto;
        border: 0;
        padding-top: 10px;
        box-sizing: border-box;
        // line-height: 72px !important;
    }
}
</style>
