var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c(
      "div",
      [
        _c(
          "div",
          { staticClass: "content-index" },
          [
            _c(
              "ScaleScreen",
              {
                staticClass: "scale-wrap",
                attrs: {
                  width: 1920,
                  height: 1080,
                  selfAdaption: _vm.selfAdaption,
                },
              },
              [
                _c("div", { staticClass: "top" }, [
                  _c("div", { staticClass: "top-l" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.date.year) +
                        "-" +
                        _vm._s(_vm.date.month) +
                        "-" +
                        _vm._s(_vm.date.date) +
                        " " +
                        _vm._s(_vm.date.hours) +
                        ":" +
                        _vm._s(_vm.date.minutes)
                    ),
                  ]),
                  _c("div", { staticClass: "week" }, [
                    _vm._v(_vm._s(_vm.weeks)),
                  ]),
                  _c("div", { staticClass: "top-cot" }, [
                    _vm._v("新域伟科技无感考勤系统"),
                  ]),
                  _c("div", { staticClass: "top-r" }, [
                    _vm.ipConfig.hostIp
                      ? _c("div", [
                          _vm._v("本机IP:" + _vm._s(_vm.ipConfig.hostIp)),
                          _c(
                            "s",
                            {
                              staticStyle: {
                                "margin-left": "15px",
                                color: "#00D43C",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openIpConfigDialog()
                                },
                              },
                            },
                            [_vm._v("修改")]
                          ),
                        ])
                      : _c(
                          "div",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.openIpConfigDialog()
                              },
                            },
                          },
                          [_vm._v("配置本机IP")]
                        ),
                  ]),
                ]),
                _c("div", { staticClass: "content" }, [
                  _c(
                    "div",
                    {
                      staticClass: "leftChoose",
                      on: { click: _vm.openOrClose },
                    },
                    [
                      _c("img", {
                        staticStyle: { width: "35px", height: "40px" },
                        attrs: { src: _vm.imgChange },
                      }),
                    ]
                  ),
                  _vm.leftOpen
                    ? _c(
                        "div",
                        { staticClass: "leftOpen" },
                        _vm._l(_vm.hostCameraList, function (item, index) {
                          return _c(
                            "div",
                            { staticClass: "theHost" },
                            [
                              _c(
                                "div",
                                { staticStyle: { "margin-bottom": "10px" } },
                                [_vm._v("主机：" + _vm._s(item.hostName))]
                              ),
                              _vm._l(item.cameraList, function (s, i) {
                                return _c(
                                  "div",
                                  {
                                    staticClass: "chooseCamera",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeCamera(s)
                                      },
                                    },
                                  },
                                  [_vm._v("摄像头：" + _vm._s(s.cameraName))]
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "video" }, [
                    _c("video", {
                      staticStyle: { "object-fit": "fill" },
                      attrs: {
                        id: "video",
                        autoplay: "",
                        muted: "",
                        width: "100%",
                        height: "100%",
                      },
                      domProps: { muted: true },
                    }),
                  ]),
                  _c(
                    "div",
                    { staticClass: "rightShow" },
                    _vm._l(_vm.peopleList, function (item, index) {
                      return _c(
                        "div",
                        { staticStyle: { "text-align": "center" } },
                        [
                          _c("img", {
                            staticStyle: {
                              width: "100%",
                              height: "164px",
                              "margin-top": "30px",
                            },
                            attrs: { src: item.image },
                          }),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#FFFFFF",
                                "font-size": "21px",
                                "margin-top": "10px",
                              },
                            },
                            [_vm._v("姓名：" + _vm._s(item.name))]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "#FFFFFF",
                                "font-size": "16px",
                                "margin-top": "10px",
                              },
                            },
                            [_vm._v("时间：" + _vm._s(item.time))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
        _c(
          "el-dialog",
          {
            attrs: {
              title: "配置本机IP",
              visible: _vm.open,
              width: "680px",
              "append-to-body": "",
            },
            on: {
              "update:visible": function ($event) {
                _vm.open = $event
              },
              closed: _vm.closeDialog,
            },
          },
          [
            _c(
              "el-form",
              {
                ref: "ipConfig",
                attrs: {
                  model: _vm.ipConfig,
                  rules: _vm.ipConfigRule,
                  "label-width": "180px",
                },
                nativeOn: {
                  submit: function ($event) {
                    $event.preventDefault()
                  },
                },
              },
              [
                _c(
                  "el-row",
                  { staticStyle: { position: "relative" } },
                  [
                    _c(
                      "el-col",
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "本机IP:", prop: "hostIp" } },
                          [
                            _c("el-input", {
                              staticStyle: { width: "200px" },
                              attrs: { placeholder: "请输入" },
                              model: {
                                value: _vm.ipConfig.hostIp,
                                callback: function ($$v) {
                                  _vm.$set(_vm.ipConfig, "hostIp", $$v)
                                },
                                expression: "ipConfig.hostIp",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "dialog-footer",
                attrs: { slot: "footer" },
                slot: "footer",
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.submitIpConfig },
                  },
                  [_vm._v("确 定")]
                ),
                _c("el-button", { on: { click: _vm.ConfigCancel } }, [
                  _vm._v("取 消"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }