var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _vm.personType == 1
      ? _c("div", [
          _c(
            "div",
            { staticClass: "content-index" },
            [
              _c(
                "ScaleScreen",
                {
                  staticClass: "scale-wrap",
                  attrs: {
                    width: 1920,
                    height: 1080,
                    selfAdaption: _vm.selfAdaption,
                  },
                },
                [
                  _c("div", { staticClass: "top" }, [
                    _c("div", { staticClass: "top-l" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.date.year) +
                          "-" +
                          _vm._s(_vm.date.month) +
                          "-" +
                          _vm._s(_vm.date.date) +
                          " " +
                          _vm._s(_vm.date.hours) +
                          ":" +
                          _vm._s(_vm.date.minutes)
                      ),
                    ]),
                    _c("div", { staticClass: "week" }, [
                      _vm._v(_vm._s(_vm.weeks)),
                    ]),
                    _c("div", { staticClass: "top-cot" }, [
                      _vm._v("新域伟科技数据可视化系统"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "top-r",
                        on: {
                          click: function ($event) {
                            return _vm.getInit(1)
                          },
                        },
                      },
                      [
                        _c("div", { staticClass: "r-box" }, [
                          _c("img", {
                            attrs: {
                              src: require("@/assets/DataSet/refresh.png"),
                              alt: "",
                            },
                          }),
                          _c("span", [_vm._v("刷新数据")]),
                        ]),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "content" }, [
                    _c(
                      "div",
                      { staticClass: "content-left" },
                      [
                        _c("div", { staticClass: "grow-box" }, [
                          _c("div", { staticClass: "school-grow" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "100%",
                                  display: "flex",
                                  "align-items": "center",
                                  "font-weight": "700",
                                },
                                attrs: { id: "diaDateTit" },
                              },
                              [_c("div", [_vm._v(_vm._s(_vm.isSchoolGName))])]
                            ),
                            _vm.isSchoolG
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "g-content",
                                    attrs: { id: "diaDate" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "g-c-i",
                                        class:
                                          _vm.isSchoolGAc == 1 ? "item-ac" : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.schoolGrowClick(1)
                                          },
                                        },
                                      },
                                      [_vm._v(" 用户增长数据")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "g-c-i",
                                        class:
                                          _vm.isSchoolGAc == 2 ? "item-ac" : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.schoolGrowClick(2)
                                          },
                                        },
                                      },
                                      [_vm._v("人脸绑定增长数据")]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm.isSchoolGAc == 2
                            ? _c(
                                "div",
                                { staticClass: "year-box" },
                                _vm._l(_vm.stuMouList, function (v, i) {
                                  return _c(
                                    "div",
                                    {
                                      key: i,
                                      staticClass: "year-item",
                                      class:
                                        i == _vm.stuActiveDate ? "year-ac" : "",
                                      on: {
                                        click: function ($event) {
                                          return _vm.stuDateClickPay(i)
                                        },
                                      },
                                    },
                                    [_vm._v(" " + _vm._s(v))]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSchoolGAc == 1,
                                expression: "isSchoolGAc == 1",
                              },
                            ],
                            class: _vm.isSchoolG ? "is-g" : "",
                            staticStyle: {
                              width: "396px",
                              height: "240px",
                              "margin-top": "30px",
                            },
                            attrs: { id: "schoolGrowEchart" },
                          }),
                          _c("div", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.isSchoolGAc == 2,
                                expression: "isSchoolGAc == 2",
                              },
                            ],
                            class: _vm.isSchoolG ? "is-g" : "",
                            staticStyle: {
                              width: "430px",
                              height: "240px",
                              "margin-top": "30px",
                            },
                            attrs: { id: "studentEchart" },
                          }),
                        ]),
                        _vm._l(_vm.recList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "grow-box" },
                            [
                              _c("div", { staticClass: "xss-grow" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "school-select",
                                    on: {
                                      click: function ($event) {
                                        return _vm.schoolSelect(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(_vm._s(item.schoolName)),
                                    ]),
                                    _c("div", { staticClass: "s-g-bar" }),
                                  ]
                                ),
                                item.schoolAc
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "school-alert",
                                        attrs: { id: "diaDate" },
                                      },
                                      _vm._l(
                                        index == 1 && _vm.isuseAc == 2
                                          ? _vm.schoolPayList
                                          : _vm.schoolList,
                                        function (v, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass: "a-item",
                                              class:
                                                i == item.schoolIndex
                                                  ? "a-item-ac"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.schoolItemClick(
                                                    i,
                                                    v,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.schoolName) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "xss-name",
                                    on: {
                                      click: function ($event) {
                                        $event.stopPropagation()
                                        return _vm.openUse(index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          index == 0 ? item.name : _vm.isuseName
                                        )
                                      ),
                                    ]),
                                    item.name != "小程序活跃率统计"
                                      ? _c("div", { staticClass: "s-g-bar" })
                                      : _vm._e(),
                                    _vm.isUseVis && index == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "xss-name-tit",
                                            attrs: { id: "diaDate" },
                                          },
                                          _vm._l(_vm.useList, function (v, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "g-c-i",
                                                class:
                                                  _vm.isuseAc == i
                                                    ? "item-ac"
                                                    : "",
                                                on: {
                                                  click: function ($event) {
                                                    $event.stopPropagation()
                                                    return _vm.useClick(v, i)
                                                  },
                                                },
                                              },
                                              [_vm._v(" " + _vm._s(v))]
                                            )
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                index == 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "mini-app",
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.miniTitClick($event)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "white-space": "nowrap",
                                            },
                                          },
                                          [_vm._v(_vm._s(_vm.miniTypeName))]
                                        ),
                                        _c("div", { staticClass: "s-g-bar" }),
                                        _vm.miniOpen
                                          ? _c(
                                              "div",
                                              { staticClass: "mini-alert" },
                                              _vm._l(
                                                _vm.miniType,
                                                function (typeItem, typeIndex) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      staticClass: "a-item",
                                                      class:
                                                        _vm.miniTypeIndex ==
                                                        typeIndex
                                                          ? "a-item-ac"
                                                          : "",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.miniClick(
                                                            typeIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(typeItem) +
                                                          " "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  {
                                    staticClass: "year-box",
                                    class: index == 0 ? "year-box-hy" : "",
                                  },
                                  _vm._l(item.dateList, function (v, i) {
                                    return _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "year-item",
                                        class:
                                          i == item.activeDate ? "year-ac" : "",
                                        on: {
                                          click: function ($event) {
                                            return _vm.dateClick(i, index)
                                          },
                                        },
                                      },
                                      [_vm._v(" " + _vm._s(v) + " ")]
                                    )
                                  }),
                                  0
                                ),
                              ]),
                              index == 0
                                ? _c("div", {
                                    class: item.schoolAc ? "is-g" : "",
                                    staticStyle: {
                                      width: "100%",
                                      height: "205px",
                                      "margin-top": "60px",
                                    },
                                    attrs: { id: "xssEchart" },
                                  })
                                : _vm._e(),
                              index == 1
                                ? _c("div", [
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isuseAc == 0,
                                          expression: "isuseAc == 0",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "phonePayUse" },
                                    }),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isuseAc == 1,
                                          expression: "isuseAc == 1",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "schoolBooks" },
                                    }),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isuseAc == 2,
                                          expression: "isuseAc == 2",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "schoolIncome" },
                                    }),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isuseAc == 3,
                                          expression: "isuseAc == 3",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "entrance" },
                                    }),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value:
                                            _vm.isuseAc == 4 ||
                                            _vm.isuseAc == 5,
                                          expression:
                                            "(isuseAc == 4 || isuseAc == 5)",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "laundry" },
                                    }),
                                    _c("div", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isuseAc == 6,
                                          expression: "(isuseAc == 6)",
                                        },
                                      ],
                                      class:
                                        item.schoolAc || _vm.isUseVis
                                          ? "is-g"
                                          : "",
                                      staticStyle: {
                                        width: "440px",
                                        height: "240px",
                                        "margin-top": "40px",
                                      },
                                      attrs: { id: "vending" },
                                    }),
                                  ])
                                : _vm._e(),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "content-center" }, [
                      _c("div", { staticClass: "c-top" }, [
                        _c(
                          "div",
                          { staticClass: "c-t-box" },
                          _vm._l(_vm.rhoList, function (item, index) {
                            return _c(
                              "div",
                              {
                                staticClass: "c-t-rho",
                                style: {
                                  "background-image": "url(" + item.img + ")",
                                  "background-repeat": "no-repeat",
                                  "background-size": "cover",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "rho-num",
                                    style: {
                                      color: item.colorTxt,
                                      "font-size": "24px",
                                    },
                                  },
                                  [
                                    item.name == "每日消费笔数"
                                      ? _c(
                                          "div",
                                          [
                                            item.num - 0
                                              ? [
                                                  _c("animate-number", {
                                                    key: item.num,
                                                    attrs: {
                                                      duration: "500",
                                                      from: "0",
                                                      to: item.num,
                                                    },
                                                  }),
                                                ]
                                              : _c("div", [_vm._v("0")]),
                                          ],
                                          2
                                        )
                                      : item.name == "每日消费金额"
                                      ? _c(
                                          "div",
                                          [
                                            item.num - 0
                                              ? _c("animate-number", {
                                                  key: item.num,
                                                  attrs: {
                                                    duration: "500",
                                                    from: "0",
                                                    to: item.num,
                                                    formatter:
                                                      _vm.formatterRate,
                                                  },
                                                })
                                              : _c("div", [_vm._v("0")]),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "div",
                                          [
                                            item.num
                                              ? _c("animate-number", {
                                                  key: item.num,
                                                  attrs: {
                                                    duration: "500",
                                                    from: "0",
                                                    to: item.num,
                                                  },
                                                })
                                              : _c("div", [_vm._v("0")]),
                                          ],
                                          1
                                        ),
                                    _c(
                                      "div",
                                      { staticStyle: { "font-size": "20px" } },
                                      [_vm._v(_vm._s(item.dw))]
                                    ),
                                  ]
                                ),
                                _c("div", { staticClass: "rho-name" }, [
                                  _c("div", [_vm._v(_vm._s(item.name))]),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "rho-tip",
                                      on: {
                                        click: function ($event) {
                                          return _vm.tipClick(index)
                                        },
                                      },
                                    },
                                    [
                                      index == 3 || index == 4
                                        ? _c("div", [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (
                                                    index == 3
                                                      ? _vm.schoolTipName
                                                      : _vm.schoolTipNameMon
                                                  )
                                                    ? index == 3
                                                      ? _vm.schoolTipName
                                                      : _vm.schoolTipNameMon
                                                    : item.tip
                                                ) +
                                                " "
                                            ),
                                          ])
                                        : _c("div", [_vm._v(_vm._s(item.tip))]),
                                      item.name == "每日消费笔数" ||
                                      item.name == "每日消费金额"
                                        ? _c("div", { staticClass: "s-g-bar" })
                                        : _vm._e(),
                                      index == 3 && _vm.schoolTipAc
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "school-alert",
                                              attrs: { id: "diaDate" },
                                            },
                                            _vm._l(
                                              _vm.schoolPayList,
                                              function (v, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "a-item",
                                                    class:
                                                      i == _vm.schoolTipIndex
                                                        ? "a-item-ac"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.schoolItemClickTip(
                                                          i,
                                                          v,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.schoolName) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                      index == 4 && _vm.schoolTipAcMon
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "school-alert",
                                              attrs: { id: "diaDate" },
                                            },
                                            _vm._l(
                                              _vm.schoolPayList,
                                              function (v, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "a-item",
                                                    class:
                                                      i == _vm.schoolTipIndexMon
                                                        ? "a-item-ac"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        $event.stopPropagation()
                                                        return _vm.schoolItemClickTipMon(
                                                          i,
                                                          v,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(v.schoolName) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "work-list" }, [
                          _c(
                            "div",
                            {
                              staticClass: "work-item",
                              staticStyle: { flex: "2.5" },
                            },
                            [
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#01E0D9",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.jxsx
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.jxsx,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.jxsx,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园数智公话")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#FEDB65",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.bp
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.bp,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.bp,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园数智班牌")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#FEDB65",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.mj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.mj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.mj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园刷脸门禁")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#01E0D9",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.cfj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.cfj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.cfj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园吹风机")]),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "work-item",
                              staticStyle: { position: "relative" },
                            },
                            [
                              _c("img", {
                                staticStyle: {
                                  width: "214px",
                                  height: "285px",
                                  "margin-bottom": "50px",
                                },
                                attrs: {
                                  src: require("@/assets/DataSet/center.png"),
                                },
                              }),
                              _c("div", { staticClass: "work-txt" }, [
                                _vm._v("已部署设备台数"),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "work-item",
                              staticStyle: { flex: "2.5" },
                            },
                            [
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#01E0D9",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.xfj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.xfj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.xfj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台"),
                                  ]
                                ),
                                _c("div", [_vm._v("校园团餐机")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#FEDB65",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.xyj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.xyj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.xyj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园洗衣机")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#FEDB65",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.ysj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.ysj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.ysj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园饮水机")]),
                              ]),
                              _c("div", { staticClass: "work" }, [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      color: "#01E0D9",
                                      "margin-bottom": "5px",
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { "font-size": "28px" } },
                                      [
                                        _vm.deviceObj.shj
                                          ? _c("animate-number", {
                                              key: _vm.deviceObj.shj,
                                              attrs: {
                                                duration: "1500",
                                                from: "0",
                                                to: _vm.deviceObj.shj,
                                              },
                                            })
                                          : _c("span", [_vm._v("0")]),
                                      ],
                                      1
                                    ),
                                    _vm._v("台 "),
                                  ]
                                ),
                                _c("div", [_vm._v("校园售货机")]),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                      _c(
                        "div",
                        { staticClass: "c-bottom" },
                        _vm._l(_vm.payList, function (item, index) {
                          return _c(
                            "div",
                            { key: index, staticClass: "bottom-box" },
                            [
                              _c("div", { staticClass: "pay-box" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "pay-top",
                                    on: {
                                      click: function ($event) {
                                        return _vm.payClick(item, index)
                                      },
                                    },
                                  },
                                  [
                                    _c("div", { staticClass: "pay-tit" }, [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(
                                            index == 0
                                              ? item.name
                                              : _vm.isPayTypeMouName
                                          )
                                        ),
                                      ]),
                                      index == 1
                                        ? _c("div", { staticClass: "s-g-bar" })
                                        : _vm._e(),
                                    ]),
                                    _c("img", {
                                      staticStyle: {
                                        display: "block",
                                        width: "323px",
                                        height: "43px",
                                        margin: "12px auto 0",
                                      },
                                      attrs: {
                                        src: require("@/assets/DataSet/pay-top.png"),
                                        alt: "",
                                      },
                                    }),
                                    _vm.isPayTypeMou && index == 1
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "g-content",
                                            attrs: { id: "diaDate" },
                                          },
                                          _vm._l(
                                            _vm.payTypeMouList,
                                            function (v, i) {
                                              return _c(
                                                "div",
                                                {
                                                  key: i,
                                                  staticClass: "g-c-i",
                                                  class:
                                                    _vm.isPayTypeMouAc == i
                                                      ? "item-ac"
                                                      : "",
                                                  on: {
                                                    click: function ($event) {
                                                      $event.stopPropagation()
                                                      return _vm.schoolGrowMouClick(
                                                        i
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v(" " + _vm._s(v) + " ")]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _vm._e(),
                                  ]
                                ),
                                index == 1
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "school-select",
                                        on: {
                                          click: function ($event) {
                                            return _vm.schoolSelectPay(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(item.schoolName)),
                                        ]),
                                        _c("div", { staticClass: "s-g-bar" }),
                                      ]
                                    )
                                  : _vm._e(),
                                item.schoolAc
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "school-alert",
                                        attrs: { id: "diaDate" },
                                      },
                                      _vm._l(
                                        _vm.schoolPayList,
                                        function (v, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass: "a-item",
                                              class:
                                                i == item.schoolIndex
                                                  ? "a-item-ac"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.schoolItemClickPay(
                                                    i,
                                                    v,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.schoolName) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                                index == 0
                                  ? _c(
                                      "div",
                                      { staticClass: "year-box" },
                                      _vm._l(item.dateList, function (v, i) {
                                        return _c(
                                          "div",
                                          {
                                            key: i,
                                            staticClass: "year-item",
                                            class:
                                              i == item.activeDate
                                                ? "year-ac"
                                                : "",
                                            on: {
                                              click: function ($event) {
                                                return _vm.dateClickPay(
                                                  i,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" " + _vm._s(v) + " ")]
                                        )
                                      }),
                                      0
                                    )
                                  : _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "school-select",
                                          staticStyle: {
                                            left: "unset",
                                            right: "24px",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.schoolSelectPayType(
                                                index
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(_vm._s(item.payTypeName)),
                                          ]),
                                          _c("div", { staticClass: "s-g-bar" }),
                                        ]
                                      ),
                                      item.payTypeAc
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "school-alert",
                                              staticStyle: {
                                                left: "unset",
                                                right: "24px",
                                              },
                                              attrs: { id: "diaDate" },
                                            },
                                            _vm._l(
                                              item.payTypeList,
                                              function (v, i) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass: "a-item",
                                                    class:
                                                      i == item.payTypeIndex
                                                        ? "a-item-ac"
                                                        : "",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.schoolItemClickPayType(
                                                          i,
                                                          v,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(v) + " "
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ]),
                              ]),
                              index == 0
                                ? _c("div", { staticClass: "tip-box" }, [
                                    _c(
                                      "div",
                                      {
                                        class: { anim: _vm.animate == true },
                                        on: {
                                          mouseenter: _vm.handleMouseEnter,
                                          mouseleave: _vm.handleMouseLeave,
                                        },
                                      },
                                      _vm._l(
                                        _vm.payRecord,
                                        function (item, index) {
                                          return _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                                "justify-content":
                                                  "space-between",
                                                "margin-bottom": "22px",
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    display: "flex",
                                                    "font-size": "14px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "tip",
                                                      class:
                                                        item.deviceType == 0
                                                          ? ""
                                                          : item.deviceType == 1
                                                          ? "tip-szgh"
                                                          : item.deviceType == 2
                                                          ? "tip-cfj"
                                                          : item.deviceType == 3
                                                          ? "tip-xyj"
                                                          : item.deviceType == 4
                                                          ? "tip-shj"
                                                          : item.deviceType == 5
                                                          ? "tip-ysj"
                                                          : "",
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.deviceType == 0
                                                              ? "校园团餐"
                                                              : item.deviceType ==
                                                                1
                                                              ? "数智公话"
                                                              : item.deviceType ==
                                                                2
                                                              ? "吹风机"
                                                              : item.deviceType ==
                                                                3
                                                              ? "洗衣机"
                                                              : item.deviceType ==
                                                                4
                                                              ? "售货机"
                                                              : item.deviceType ==
                                                                5
                                                              ? "饮水机"
                                                              : ""
                                                          ) +
                                                          " "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        width: "240px",
                                                        "white-space": "nowrap",
                                                        "text-overflow":
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            item.userName
                                                              ? item.userName
                                                              : ""
                                                          ) +
                                                          " 消费 "
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            color: "#FFB82E",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.consumptionMoney
                                                                  ? item.consumptionMoney
                                                                  : "0.00"
                                                              ) +
                                                              "元 "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "font-size": "12px",
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        item.consumptionTime
                                                          ? item.consumptionTime
                                                          : ""
                                                      ) +
                                                      " "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              index == 1
                                ? _c("div", {
                                    class:
                                      item.schoolAc ||
                                      item.payTypeAc ||
                                      _vm.isPayTypeMou
                                        ? "is-g"
                                        : "",
                                    staticStyle: {
                                      width: "430px",
                                      height: "75%",
                                      "margin-top": "40px",
                                    },
                                    attrs: { id: "payNumEchart" },
                                  })
                                : _vm._e(),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                    _c("div", { staticClass: "content-right" }, [
                      _c("div", { staticClass: "map-box" }, [
                        _c("div", { staticClass: "map-tit" }, [
                          _vm._v("已合作学校分布图"),
                        ]),
                        _c("div", {
                          staticStyle: { width: "100%", height: "100%" },
                          attrs: { id: "mapEchart" },
                        }),
                        _c("div", { staticClass: "map-info" }, [
                          _c("div", { staticClass: "info-area" }, [
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  color: "#66FFFF",
                                  "font-size": "16px",
                                },
                              },
                              [_vm._v("全国 · " + _vm._s(_vm.allSchool) + "所")]
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  height: "20px",
                                  display: "flex",
                                  "align-items": "center",
                                  cursor: "pointer",
                                  position: "relative",
                                  margin: "15px 0",
                                },
                                on: { click: _vm.mapAlertClick },
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    _vm._s(_vm.cityName) +
                                      " · " +
                                      _vm._s(_vm.citySchoolList.length) +
                                      "所"
                                  ),
                                ]),
                                this.citySchoolList.length != 0
                                  ? _c(
                                      "div",
                                      { staticStyle: { margin: "0 18px" } },
                                      [_vm._v(_vm._s(_vm.citySchoolName))]
                                    )
                                  : _vm._e(),
                                this.citySchoolList.length != 0
                                  ? _c("div", { staticClass: "s-g-bar" })
                                  : _vm._e(),
                                _vm.mapVis
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "school-alert",
                                        attrs: { id: "diaDate" },
                                      },
                                      _vm._l(
                                        _vm.citySchoolList,
                                        function (v, i) {
                                          return _c(
                                            "div",
                                            {
                                              key: i,
                                              staticClass: "a-item",
                                              class:
                                                i == _vm.citySchoolIndex
                                                  ? "a-item-ac"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  $event.stopPropagation()
                                                  return _vm.schoolAreaClick(
                                                    i,
                                                    v
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(v.schoolName) + " "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                display: "none",
                                color: "#FEDB65",
                                "margin-top": "8px",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "margin-right": "48px",
                                    color: "#66FFFF",
                                  },
                                },
                                [
                                  _vm._v(
                                    "代理商：" +
                                      _vm._s(
                                        _vm.mapObj.agentName
                                          ? _vm.mapObj.agentName
                                          : "无"
                                      )
                                  ),
                                ]
                              ),
                              _c("div", [
                                _vm._v(
                                  "校收收已绑定人数：" +
                                    _vm._s(
                                      _vm.mapObj.xssBindUserCount
                                        ? _vm.mapObj.xssBindUserCount
                                        : "0"
                                    )
                                ),
                              ]),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                "margin-top": "8px",
                                color: "#fff",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "font-size": "14px",
                                    "margin-top": "5px",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-size": "16px",
                                        "font-weight": "700",
                                      },
                                    },
                                    [_vm._v("设备部署：")]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      _vm.mapObj.deviceInfo
                                        ? _vm.mapObj.deviceInfo
                                        : "无"
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]),
                      _c("div", { staticClass: "ims-box" }, [
                        _c("div", { staticClass: "ims-tit" }, [
                          _vm._v("IMS/微信音视频阈值告警"),
                        ]),
                        _c(
                          "div",
                          { staticClass: "ims-wrap" },
                          _vm._l(_vm.imsTop, function (item, index) {
                            return _c(
                              "div",
                              { key: index, staticClass: "ims-top-box" },
                              [
                                _c("div", [
                                  _c("div", { staticClass: "ims" }, [
                                    _vm._v(_vm._s(item.name)),
                                  ]),
                                  _c("div", { staticClass: "ims-num" }, [
                                    index == 2
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#fff",
                                            },
                                          },
                                          [_vm._v("前")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    index == 2
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#FEDB65" } },
                                          [
                                            item.num
                                              ? _c("animate-number", {
                                                  key: item.num,
                                                  attrs: {
                                                    duration: "1500",
                                                    from: "0",
                                                    to: item.num,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    index != 2
                                      ? _c(
                                          "span",
                                          { staticStyle: { color: "#129BFF" } },
                                          [
                                            item.num
                                              ? _c("animate-number", {
                                                  key: item.num,
                                                  attrs: {
                                                    duration: "1500",
                                                    from: "0",
                                                    to: item.num,
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    index != 2
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#fff",
                                            },
                                          },
                                          [_vm._v("分钟")]
                                        )
                                      : _vm._e(),
                                    index == 2
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "12px",
                                              color: "#fff",
                                            },
                                          },
                                          [_vm._v("天")]
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                                _c("div", { staticClass: "i-bar" }),
                              ]
                            )
                          }),
                          0
                        ),
                        _c("div", { staticClass: "ims-bottom" }, [
                          _c("div", { staticClass: "i-b-t" }, [
                            _vm._v("近三日报警信息"),
                          ]),
                          _c(
                            "div",
                            { staticStyle: { padding: "15px 0" } },
                            [
                              _c("dv-scroll-board", {
                                staticStyle: {
                                  width: "100%",
                                  height: "160px",
                                  padding: "5px 10px 0",
                                },
                                attrs: { config: _vm.config },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ])
      : _c(
          "div",
          { staticClass: "content-index " },
          [
            _c("DataSetSchool", {
              staticClass: "scale-wrap",
              attrs: {
                width: 1920,
                height: 1080,
                selfAdaption: _vm.selfAdaption,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }