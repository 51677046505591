<template>
  <div :class="classObj" class="app-wrapper" :style="{'--current-color': theme}">
    <div v-if="device==='mobile'&&sidebar.opened" class="drawer-bg" @click="handleClickOutside"/>
    <sidebar v-if="!sidebar.hide" class="sidebar-container" />
    <div :class="{hasTagsView:needTagsView,sidebarHide:sidebar.hide}" class="main-container">
      <div :class="{'fixed-header':fixedHeader}">
        <navbar />
        <tags-view v-if="needTagsView" />
        <div class="quziBlock" >
          <img v-if="hoverStatus" src="../assets/icons/quzi-ts.png" class="title">
          <img class="icon" @click="openQuzi" src="../assets/icons/quzi.png"  @mouseover="onMouseOver" @mouseout="onMouseOut">
        </div>
        <el-drawer
          :visible.sync="drawer"
          :direction="direction"
          size="50%"
           z-index="99999">
          <iframe src="https://chatbot.weixin.qq.com/webapp/fMP4Axp4LsyCsWXv6yQJif7zLOffh8?robotName=%E5%B0%8F%E5%9F%9F%E6%9C%8D%E5%8A%A1%E5%95%86%E5%8A%A9%E6%89%8B" frameborder="no" border="0" marginwidth="0"
                  marginheight="0" width="100%" height="100%" style="overflow: hidden">
          </iframe>
        </el-drawer>
      </div>
      <app-main />
      <right-panel>
        <settings />
      </right-panel>
    </div>

  </div>
</template>

<script>
import RightPanel from '@/components/RightPanel'
import { AppMain, Navbar, Settings, Sidebar, TagsView } from './components'
import ResizeMixin from './mixin/ResizeHandler'
import { mapState } from 'vuex'
import variables from '@/assets/styles/variables.scss'
import logoImg from '@/assets/logo/logo.png'

export default {
  name: 'Layout',
  components: {
    AppMain,
    Navbar,
    RightPanel,
    Settings,
    Sidebar,
    TagsView
  },
  data() {
    return {
      hoverStatus:false,
      drawer: false,
      direction: 'rtl',
    }
  },
  mixins: [ResizeMixin],
  computed: {
    ...mapState({
      theme: state => state.settings.theme,
      sideTheme: state => state.settings.sideTheme,
      sidebar: state => state.app.sidebar,
      device: state => state.app.device,
      needTagsView: state => state.settings.tagsView,
      fixedHeader: state => state.settings.fixedHeader
    }),
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    },
    variables() {
      return variables;
    }
  },
  methods: {
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },

    onMouseOver() {
      this.hoverStatus = true;
    },
    onMouseOut() {
      this.hoverStatus = false;
    },
    openQuzi(){
      this.drawer = true
       // window.open('https://chatbot.weixin.qq.com/webapp/fMP4Axp4LsyCsWXv6yQJif7zLOffh8?robotName=%E5%B0%8F%E5%9F%9F%E6%9C%8D%E5%8A%A1%E5%95%86%E5%8A%A9%E6%89%8B')
    },
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/assets/styles/mixin.scss";
  @import "~@/assets/styles/variables.scss";

  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;

    &.mobile.openSidebar {
      position: fixed;
      top: 0;
    }
  }

  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$base-sidebar-width});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px);
  }

  .sidebarHide .fixed-header {
    width: 100%;
  }

  .mobile .fixed-header {
    width: 100%;
  }

  .quziBlock{
    position: fixed;
    width: 70px;
    height: 100px;
    z-index: 9999;
    right: 0;
    bottom: 0
  }
  .quziBlock .title{
    height: 25px;
    position: absolute;
    width: 60px
  }

  .quziBlock .icon{
    width: 70px;
    height: 70px;
    margin-top: 25px;
    cursor: pointer
  }

</style>
