import mqtt from "mqtt";
import Vue from 'vue'
var vm = new Vue();
class mqttHandle {
    // subscribe
    constructor() {
        this.connect = {
            connectTimeout: 4000,
            clean: true, // 保留会话
            connectTimeout: 4000, // 超时时间
            reconnectPeriod: 4000, // 重连时间间
            clientId: Date.now(),//唯一值
            // port: 15675,//端口
            // host: 'mqtt://39.98.176.146',//地址
            username: process.env.VUE_APP_MQTT,//id            		 
            password: process.env.VUE_APP_MQTTPwd,//密码
        }
        // this.subscription = {
        //     topic: subscribe,  //需要传入数组的包含订阅的名称
        //     qos: 2,
        // }
        this.mqttClient = null;
    }
    /**
     * 创建链接
     * @returns client
     */

    // mqtt链接
    createConnect() {
        this.client = mqtt.connect( process.env.VUE_APP_Url, this.connect);
        this.client.on('connect', e => {
            console.log('服务器链接成功');
        })
        this.mqttClient = this.client;
        return this.mqttClient;
        //信息监听事件
        // this.client.on('message', (topic, message) => {
        //     this.isMsg = true
        //     let OperationCode = JSON.parse(message.toString()).OperationCode;
        //     console.log('收到' + message.toString());
        //     console.log('收到' + topic);
        //     // 网关id
        //     let code = topic.replace(/[^\d]/g, '') - 0
        //     console.log(code, 'code');
        //     if (OperationCode == 61 && code == this.gatewayNum) {
        //         this.online = '1'
        //     }
        //     this.loading = false;
        // })
        // //重连
        // this.client.on('reconnect', (err) => {
        //     console.log('正在进行重连', err);
        // })
        // //失败
        // this.client.on('error', (err) => {
        //     console.log('连接失败', err);
        // })

    }
    // createConnect() {
    //    配置链接
    //     const { host, port, endpoint, ...options } = this.connect;
    //     const connectUrl = `ws://${this.connect.host}:${this.connect.port}${this.connect.endpoint}`;
    //     try {
    //         this._client = mqtt.connect(connectUrl, options);

    //     } catch (error) {
    //         console.log("mqtt.connect error", error);
    //     }
    //     this._client.on("connect", () => {
    //         console.log("Connection succeeded!");
    //     });
    //     this._client.on('reconnect', (error) => {
    //         console.log('正在重连', error)
    //     })
    //     this._client.on("error", (error) => {
    //         console.log("Connection failed", error);
    //     });

    //配置topic
    // const { topic, qos } = this.subscription;
    // this._client.subscribe(topic, { qos: qos }, (error, res) => {
    //     if (error) {
    //         console.log("Subscribe to topics error", error);
    //         return;
    //     }
    //     this.subscribeSuccess = true;
    //     // console.log("Subscribe to topics res", res[0].qos, res[0].topic);
    // });
    // this.mqttClient = this.client;
    // return this.mqttClient;
    // }
}

export default mqttHandle;