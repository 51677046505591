import request from '@/utils/request'

// 消费统计-查
export function getHostList(data) {
  return request({
    url: '/xyw-system/faceHost/getHostList',
    method: 'post',
    data
  })
}

export function saveHostInfo(data) {
  return request({
    url: '/xyw-system/faceHost/saveHostInfo',
    method: 'post',
    data
  })
}


export function deleteHostInfo(data) {
  return request({
    url: '/xyw-system/faceHost/deleteHostInfo',
    method: 'post',
    data
  })
}

export function edgeHostInfo(data) {
  return request({
    url: '/xyw-system/faceHost/edgeHostInfo',
    method: 'post',
    data
  })
}


export function getCameraList(data) {
  return request({
    url: '/xyw-system/faceCamera/getCameraList',
    method: 'post',
    data
  })
}

export function getFaceLibraryBySchool(data) {
  return request({
    url: '/xyw-system/faceCamera/getFaceLibraryBySchool',
    method: 'post',
    data
  })
}

export function saveCamera(data) {
  return request({
    url: '/xyw-system/faceCamera/save',
    method: 'post',
    data
  })
}


export function bindStuFace(data) {
  return request({
    url: '/xyw-system//faceCamera/bindStuFace',
    method: 'post',
    data
  })
}




